import { downloadZip } from "client-zip"
import { DownloadStream } from "dl-stream"

export async function getFileSize(url: string): Promise<number | undefined> {
    try {
        const response = await fetch(url, { method: 'GET', headers: { "range": "bytes=0-0" } });

        if (response.ok) {
            const size = response.headers.get("content-range")?.split("/")[1];
            if (size) {
                return parseInt(size, 10)
            } else {
                console.warn(`Failed to fetch file size for ${url}`);
                return undefined
            }
        } else {
            console.error(`Failed to fetch file size for ${url}`);
            return undefined
        }
    } catch (error) {
        console.error(`Error: ${error}`);
        return undefined
    }
}


export async function getAllAssetDetails(filteredFeatures: FeatureSearch[]) {
    // Initialize an empty dictionary to store asset details categorized by asset name
    const assetDetailsDict: Record<string, { itemId: string; href: string; filesize: number }[]> = {};

    // Iterate over each feature in the filteredFeatures array
    for (const item of filteredFeatures) {
        // Get all asset names (keys) from the assets object
        const assetNames = Object.keys(item.assets) as Array<keyof typeof item.assets>;

        // Use Promise.all to fetch file sizes concurrently for all assets of the current item
        const assetDetailsPromises = assetNames.map(async (assetName) => {
            // Extract href from the asset
            const href = item.assets[assetName].href;

            // Await the filesize for the current asset
            let filesize: number | undefined;
            try {
                filesize = await getFileSize(href);
            } catch (error) {
                console.error(`Failed to get file size for ${href}:`, error);
                filesize = 0; // Default value in case of error
            }

            // Return the asset details object with a default value for filesize if undefined
            return {
                itemId: item.id,
                assetName,
                href,
                filesize: filesize !== undefined ? filesize : 0, // Handle undefined by defaulting to 0
            };
        });

        // Wait for all promises to resolve
        const assetDetails = await Promise.all(assetDetailsPromises);

        // Populate the dictionary
        for (const detail of assetDetails) {
            if (!assetDetailsDict[detail.assetName]) {
                // Initialize the array if it doesn't exist
                assetDetailsDict[detail.assetName] = [];
            }
            // Push the detail object into the corresponding array
            assetDetailsDict[detail.assetName].push({
                itemId: detail.itemId,
                href: detail.href,
                filesize: detail.filesize,
            });
        }
    }

    return assetDetailsDict;
}

export function formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024; // Size of a kilobyte
    const dm = decimals < 0 ? 0 : decimals; // Decimal places
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

// Assuming the assetDetailsDict is already populated
type AssetDetail = { filesize: number; href: string; itemId: string };

export function getSelectedAssetsInfo(assetDetailsDict: Record<string, AssetDetail[]>, selectedKeys: string[]) {
  // Filter the assetDetailsDict based on selected keys
  const filteredAssets = selectedKeys.reduce<{ hrefs: (string | Blob)[]; names: string[]; totalSize: number }>(
    (acc, key) => {
      if (assetDetailsDict[key]) {
        // Extract hrefs and sum up the filesize
        const names = assetDetailsDict[key].map(asset => {
            const parts = asset.href.split("?")[0].split("/");
            return parts.at(-1) ?? "unknown" 
        });
        const hrefs = assetDetailsDict[key].map(asset => asset.href);
        const totalSize = assetDetailsDict[key].reduce((sum, asset) => sum + asset.filesize, 0);
        
        // Accumulate hrefs and total size
        acc.names.push(...names);
        acc.hrefs.push(...hrefs);
        acc.totalSize += totalSize;
      }
      return acc;
    },
    { hrefs: [], names: [], totalSize: 0 }
  );

  return filteredAssets;
}

type ExtraFile = { name: string, input: string | Blob };

export async function downloadFiles(
  files: any[],
  names: any[],
  extrafiles: ExtraFile[],
  fileName: string,
  onProgress: (progress: number) => void, // Callback for progress updates
  onFinished: () => void // Callback when the operation is finished
) {
  // get the ZIP stream in a Blob
  const blob = await downloadZip(
    countBytes(new DownloadStream(files), extrafiles, onProgress)
  ).blob();

  async function* countBytes(
    downloadStream: DownloadStream,
    extrafiles: ExtraFile[],
    onProgress: (progress: number) => void
  ) {
    onProgress(0); // Initialize progress
    let downloadedBytes = 0;

    // Process the streamed files
    for await (const response of downloadStream) {
      if (response.body) {
        const stream = response.body.pipeThrough(
          new TransformStream({
            transform(chunk, ctrl) {
              downloadedBytes += chunk.length;
              onProgress(downloadedBytes); // Update progress
              ctrl.enqueue(chunk);
            },
          })
        );
        yield { name: names[files.indexOf(response.url)], input: stream };
      }
    }

    // Add the extra files
    if (extrafiles) {
      for (const extra of extrafiles) {
        // Create a stream for extra file input (if it's a string, convert it to a Blob)
        let fileStream: Blob;
        if (typeof extra.input === "string") {
          fileStream = new Blob([extra.input], { type: "text/plain" });
        } else {
          fileStream = extra.input as Blob;
        }

        // Convert Blob to a stream and yield it
        const extraStream = fileStream.stream();
        yield { name: extra.name, input: extraStream };
      }
    }
  }

  // Make and click a temporary link to download the Blob
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  
  link.download = fileName

  link.click();
  link.remove();
  URL.revokeObjectURL(link.href);

  // Call onFinished when the download is complete
  onFinished();
}

export function downloadSingleFile(href: string) {
  // Create an invisible anchor element
  const anchor = document.createElement('a');
  anchor.href = href;
  anchor.download = ''; // Optionally, you can specify a filename here, or leave it empty
  
  // Append the anchor to the body
  document.body.appendChild(anchor);

  // Programmatically click the anchor to trigger the download
  anchor.click();

  // Remove the anchor from the document after download is triggered
  document.body.removeChild(anchor);
}