import React from 'react';
import { observer } from 'mobx-react-lite';

import { Router } from './routes';
import { useStores } from 'config/hooks';

import { BigToastList } from 'components/bigtoast';
import { ToastList } from 'components/toast';
import { GlobalStyles } from 'components/global';
import ReactGA from "react-ga4";
import {GA4_TRACKING_ID} from "./config/index"

const App: React.FC = observer(() => {
  const {
    rootStore: { notificationStore }
  } = useStores();

  ReactGA.initialize([
    {
      trackingId: GA4_TRACKING_ID?GA4_TRACKING_ID:""          
    },    
  ]);

  return (
    <React.Fragment>
      <BigToastList notifications={notificationStore.bigNotifications} />
      <GlobalStyles />
      <Router />
      <ToastList notifications={notificationStore.notifications} />
    </React.Fragment>
  );
});

App.displayName = 'App';

export default App;
