import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TILER_URI, PRESIGNED_OR_S3 } from 'config';

import { Layout } from 'components/layout';
import { observer } from 'mobx-react-lite';
import archiveStore from 'stores/archiveStore';
import archiveResultsStore from 'stores/archiveResultsStore';

import Map, { Source, Layer, Popup } from 'react-map-gl/maplibre';
import type { LngLatBounds, MapRef } from 'react-map-gl/maplibre';
import maplibregl, { GetResourceResponse, IControl, Map as MaplibreMap, RequestParameters } from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'maplibre-gl/dist/maplibre-gl.css';

import { MapLibreSearchControl } from "@stadiamaps/maplibre-search-box";
import "@stadiamaps/maplibre-search-box/dist/style.css";
import { ItemProvider, useItemContext } from './components/infoTab/ItemContext'; // Import the context

import { BottomContainer, LeftPane, TopBarTitle } from 'views/images/images.styles';
import { LinkButton, MapContainer, PopUpContainer } from './archive.styles';
import { convertToGeoJSON, transformDateTime, calculateOverallBBox, geoJsonBBOX, isContained, checkClockwise, convertPresignedUrlToS3Uri } from './helpers';

import { TabsContainer } from 'components/tabscontainer';

import { autorun, toJS } from 'mobx';
import { getPolygonArea } from 'config/helpers';
import { getGeometry } from '../tasking/helpers';
import { useStores } from 'config/hooks';

import { bbox, polygon, bboxPolygon } from '@turf/turf';
import { BBox, convertArea, feature } from '@turf/helpers';
import area from '@turf/area';
import { draw_styles } from './components/searchTab/helpers';
import { getRasterCoordinatesFromGeoJSON } from './helpers';
import loadingNotificationStore from 'stores/loadingNotificationStore';
import { LngLat } from 'react-map-gl';
import { useTabContext, TabProvider } from './TabContext';
import SlidingPane from "react-sliding-pane";

import "react-sliding-pane/dist/react-sliding-pane.css";
import MetadataFrame from './MetadataFrame';
import ReactModal from 'react-modal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { searchCollection } from 'services/archiveService';

import GaiaFrame from './GaiaFrame';
import { SCOPES } from 'config';
import { ContractConstraint } from 'components/scope';

const handleUpdatedBounds = (bounds: any) => {

  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();

  const boundsArray = [
    [southwest.lng, southwest.lat],
    [northeast.lng, southwest.lat],
    [northeast.lng, northeast.lat],
    [southwest.lng, northeast.lat],
    [southwest.lng, southwest.lat] // Closing the polygon
  ];
  const aoiPoly = polygon([boundsArray]);
  const aoiArea = convertArea(area(aoiPoly.geometry), 'meters', 'kilometers');
  archiveStore.setAOIArea(aoiArea);
  archiveStore.setAoiPolygon(aoiPoly);
}

interface HandleSelectCaptureOptions {
  fitCapture?: boolean;
  scrollResults?: boolean;
  openInfoTab?: boolean;
}

export const handleSelectCapture = async (
  item: FeatureSearch | any,
  token: string | undefined,
  updateTabStatus: (targetLabel: string, enabled: boolean) => void,
  options: HandleSelectCaptureOptions = {}) => {


  const {
    fitCapture = true,
    scrollResults = true,
    openInfoTab = true
  } = options;

  const bbox = item.bbox;

  const map = archiveStore.maplibreMap;

  const selectedLayer = map?.getLayer("thumbnail-" + item.id)?.id

  const layers = map?.getStyle().layers
    .filter(layer => layer.id.startsWith('thumbnail-'))

  // Center capture
  if (fitCapture) {
    archiveStore.maplibreMap?.fitBounds(
      bbox, {
      essential: true,
      duration: 750,
      padding: 20,
    })
  }

  // Fadeout effect
  if (selectedLayer) {

    layers?.forEach(layer => {



      // Show tiles
      archiveStore.setActiveCapture(item);

      if (layer.id === selectedLayer) {

        // Make opaquqe
        if (layer && layer.type === 'raster') {
          map.setPaintProperty(layer.id, 'raster-opacity', 1.0);
        }

        // Move the clicked layer on top of others
        map.moveLayer(selectedLayer);

      } else {
        // Make translucent
        if (layer && layer.type === 'raster') {
          map.setPaintProperty(layer.id, 'raster-opacity', 0.05);
        }
      }

      // Scroll results into view
      if (scrollResults) {
        let outcomeId = archiveStore.activeCapture?.properties['satl:outcome_id'];
        if (outcomeId) {
          archiveResultsStore.cardRefs[outcomeId]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }

      if (openInfoTab) {
        updateTabStatus("Details", true)
        archiveStore.setSelectedTab("Details");
      }
    });

  }
};

export const handleUnselectCapture = (
  updateTabStatus: (targetLabel: string, enabled: boolean) => void,
) => {

  const map = archiveStore.maplibreMap;

  const layers = map?.getStyle().layers
    .filter(layer => layer.id.startsWith('thumbnail-'))

  loadingNotificationStore.setLoading(false);
  archiveStore.clearActiveCaptureData();
  archiveStore.setActiveProductData(null);
  archiveStore.setActiveCapture(null);

  updateTabStatus("Details", false);
  archiveStore.setSelectedTab("Results");
  // Fadeout effect
  layers?.forEach(layer => {
    if (map?.getLayer(layer.id)) {
      // Check if the layer has a fill-opacity property and set it
      if (layer && layer.type === 'raster') {
        // Directly set the raster-opacity property
        map.setPaintProperty(layer.id, 'raster-opacity', 1.0);
      }
    }
  });
}

export const Archive = observer(() => {
  const [searchParams] = useSearchParams();
  const url_outcome_id = searchParams.get('outcome_id');

  const {
    rootStore: { userStore }
  } = useStores();

  const { selectedItems, hiddenItems, toggleItem } = useItemContext();
  const { tabs, updateTabStatus } = useTabContext();

  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);
  const [moveEvent, setMoveEvent] = useState<number[] | null>(null);

  const [telemetryPaneState, setTelemetryPaneState] = useState<boolean>(false);

  const [declutterPopupInfo, setdeclutterPopupInfo] = useState<{
    lngLat: LngLat;
    items: any;
  } | null>(null);

  const getTokenFromStore = useCallback(async () => {
    const token = await userStore.auth0Client?.getTokenSilently();
    return token;
  }, [userStore]);


  useEffect(() => {
    if (url_outcome_id) {

      archiveStore.setActiveCapture(null);
      archiveStore.clearActiveCaptureData();
      archiveStore.setActiveProductData(null);

      loadingNotificationStore.setText('Searching...');
      loadingNotificationStore.setLoading(true);
      loadingNotificationStore.setNoResults(false);

      const fetchData = async (searchCollectionParams: {}) => {

        const token = await getTokenFromStore();

        const searchData = await searchCollection(token, searchCollectionParams);

        if (searchData && searchData.features.length > 0) {
          archiveStore.setSearchResultsThumbnail(searchData);
          archiveStore.setShowMap(true);
          loadingNotificationStore.setLoading(false);

          archiveStore.setActiveCapture(searchData.features[0]);
          await handleSelectCapture(searchData.features[0], token, updateTabStatus, { 'fitCapture': true, 'scrollResults': false, 'openInfoTab': false }); //XXX: Sometimes there is more than one, it shoudl, but there is                    
          updateTabStatus("Results", true);
          updateTabStatus("Details", true); /// call it after selecting the capture to make the effects
          archiveStore.setSelectedTab("Details");

        } else {
          loadingNotificationStore.setLoading(false);
          loadingNotificationStore.setNoResults(true);
        }
      }

      try {
        const searchCollectionParams = {
          startDate: undefined,
          endDate: undefined,
          collections: ['quickview-visual-thumb'],
          limitSearchAmount: '5',
          polygon: undefined,
          'filter-lang': 'cql2-json',
          filter: {
            op: 'and',
            args: [
              {
                op: '=',
                args: [
                  {
                    property: 'satl:outcome_id'
                  },
                  url_outcome_id
                ]
              }
            ]
          }

        };
        fetchData(searchCollectionParams)

      } catch (error) {
        console.error("Error processing search collection:", error);
        loadingNotificationStore.setLoading(false);
      }
    };
  }, []);



  const updateDrawnPolygon = (feature: any) => {

    archiveStore.setAoiPolygon(
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: feature.geometry.coordinates
        }
      }
    );

    const polygonArea = getPolygonArea(archiveStore.aoiPolygon.geometry.coordinates)
    archiveStore.setAOIArea(Number(polygonArea))

  }

  const handleDrawPolygon = () => {
    if (!!drawRef) {
      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        },
        styles: draw_styles
      });


      const originalOnAdd = drawRef.current.onAdd.bind(drawRef.current);
      drawRef.current.onAdd = map => {
        const controlContainer = originalOnAdd(map);
        controlContainer.classList.add('maplibregl-ctrl', 'maplibregl-ctrl-group');
        return controlContainer;
      };

      const map = archiveStore.mapRefStore?.getMap()

      if (map) {
        map.addControl(drawRef.current, 'top-right');
        drawRef.current.changeMode("draw_polygon");

        /*
        map.on('draw.create', (e) => {

          // Limit to one polygon
          if (drawRef.current) {
            const data = drawRef.current?.getAll();
            if (data && data.features.length > 1) {
              drawRef.current.delete(data.features[0].id as string);
            }
          }

          if (drawRef.current && e.features.length > 0) updateDrawnPolygon(e.features[0]);
          //handleStopDrawPolygon(); 
        }
        );
        map.on('draw.delete', (e) => {
          //console.log('Draw delete event:', e);
          if (drawRef.current && e.features.length > 0) updateDrawnPolygon(e.features[0]);
        });

        map.on('draw.update', (e) => {
          //console.log('Draw update event:', e);
          if (drawRef.current && e.features.length > 0) updateDrawnPolygon(e.features[0]);
        });
        */
      }
    }
  }

  const handleStopDrawPolygon = () => {
    const map = archiveStore.mapRefStore?.getMap() as unknown as MaplibreMap;

    if (map && !!drawRef && !!drawRef.current) {
      map.removeControl(drawRef.current as unknown as IControl);
      drawRef.current = null;
    }
  }

  useEffect(() => {
    if (mapRef) {
      //@ts-ignore
      archiveStore.setMaplibreMap(mapRef.getMap() as MaplibreMap);
      //@ts-ignore
      archiveStore.setMapRefStore(mapRef);
      // MapboxDraw requires the canvas's class order to have the class
      // "mapboxgl-canvas" first in the list for the key bindings to work
      mapRef.getCanvas().className = 'mapboxgl-canvas maplibregl-canvas';
      mapRef.getContainer().classList.add('mapboxgl-map');
      const canvasContainer = mapRef.getCanvasContainer();
      canvasContainer.classList.add('mapboxgl-canvas-container');
      if (canvasContainer.classList.contains('maplibregl-interactive')) {
        canvasContainer.classList.add('mapboxgl-interactive');
      }

      const map = mapRef.getMap();

      map.on('zoomend', () => {
        const bounds = map.getBounds();
        if (
          archiveStore.aoiSearchMode === "CurrentCanvas"
          && archiveStore.selectedTab === "Search"
        ) handleUpdatedBounds(bounds);
      });

      map.on('move', () => {
        const bounds = map.getBounds();
        if (archiveStore.aoiSearchMode === "CurrentCanvas"
          && archiveStore.selectedTab === "Search"
        ) handleUpdatedBounds(bounds);
      });

      map.on('mousemove', (e) => {
        setMoveEvent(e.lngLat.toArray());

      });

      map.on('mouseout', (e) => {
        setMoveEvent(null);
      });

      // disable map rotation using right click + drag
      map.dragRotate.disable();

      // disable map rotation using keyboard
      map.keyboard.disable();

      // disable map rotation using touch rotation gesture
      map.touchZoomRotate.disableRotation();

      const addWaterMark = async () => {
        // Load an image to use as the pattern
        const image = await map.loadImage('/images/watermark.png');
        // Declare the image
        map.addImage('watermark', image.data);
      }

      addWaterMark();
      const control = new MapLibreSearchControl({
        onResultSelected: feature => {
          // You can add code here to take some action when a result is selected.          
        },
        // You can also use our EU endpoint to keep traffic within the EU using the basePath option:
        // baseUrl: "https://api-eu.stadiamaps.com",
      });

      if (!map.hasControl(control)) {
        map.addControl(control, "top-right");
      }

      const handleMapClick = async (event: any) => {
        const token = await getTokenFromStore();

        // Click for decluetting on results view

        if (archiveStore.selectedTab == "Results") {

          const thumbnailLayersIds = map?.getStyle().layers
            .filter(layer => layer.id.startsWith('layer-outline-'))
            .map(layer => layer.id)

          const features = map.queryRenderedFeatures(event.point, {
            layers: thumbnailLayersIds
          });

          if (features && features.length == 0) {

            handleUnselectCapture(updateTabStatus);
            setdeclutterPopupInfo(null);

          } else if (features && features.length > 0) {

            if (features && features.length > 1) {
              const clutteredLayers = features.map(clickedFeature => clickedFeature.layer.id);
              const clickedFeatureIds = features.map(clickedFeature => clickedFeature.layer.id.split("-")[2]);
              const clickedSTACItems = toJS(clickedFeatureIds.map(x => toJS(archiveStore.searchResultsThumbnail?.features.filter(feature => feature.id === x)[0])))

              setdeclutterPopupInfo({
                lngLat: event.lngLat,
                items: clickedSTACItems
              })
            } else {
              setdeclutterPopupInfo(null);
              const id = features[0].layer.id.split("-")[2];
              const item = toJS(archiveStore.searchResultsThumbnail?.features.filter(feature => feature.id == id)[0])

              if (!!item) {
                handleSelectCapture(item, token, updateTabStatus);
              }
            }
          }
        }


        // Click for selecting items in details view
        if (archiveStore.selectedTab == "Details") {
          const productItemFillsLayersIds = map?.getStyle().layers
            .filter(layer => layer.id.startsWith('product-item-fill'))
            .map(layer => layer.id)

          const features = map.queryRenderedFeatures(event.point, {
            layers: productItemFillsLayersIds
          });

          if (features && features.length == 0) {

            // Do nothing, unselecting everythign is very annoying for the user

          } else if (features && features.length > 0) {

            if (features && features.length > 1) {
              /*
              TODO: Decluttering pop up!
              const clutteredLayers = features.map(clickedFeature => clickedFeature.layer.id);
              const clickedFeatureIds = features.map(clickedFeature => clickedFeature.layer.id.split("-")[2]);
              const clickedSTACItems = toJS(clickedFeatureIds.map(x => toJS(archiveStore.searchResultsThumbnail?.features.filter(feature => feature.id === x)[0])))

              setdeclutterPopupInfo({
                lngLat: event.lngLat,
                items: clickedSTACItems
              })
              */
            } else {
              const id = features[0].properties.id;
              toggleItem(id)
              map.triggerRepaint();

              if (id) {
                archiveResultsStore.cardRefs[id]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }
          }
        }
      };

      const handleRightClick = (e: maplibregl.MapMouseEvent) => {
        e.preventDefault();
        // Remove any existing context menu
        const existingMenu = document.querySelector('.context-menu');
        if (existingMenu) {
          document.body.removeChild(existingMenu);
        }

        if (archiveStore.selectedTab === "Details") {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['product-item-fill']
          });
          if (features.length > 0) {
            const id = features[0].properties?.id;
            const outcomeId = features[0].properties?.['satl:outcome_id'];
            if (id) {
              const menu = document.createElement('div');
              menu.className = 'context-menu';
              menu.style.position = 'absolute';

              // Get the map container's bounding rectangle
              const mapContainer = map.getContainer();
              const rect = mapContainer.getBoundingClientRect();

              // Calculate the position relative to the map container
              const x = e.point.x;
              const y = e.point.y;

              menu.style.left = `${x}px`;
              menu.style.top = `${y}px`;
              menu.style.backgroundColor = 'white';
              menu.style.padding = '5px';
              menu.style.borderRadius = '3px';
              menu.style.boxShadow = '0 2px 5px rgba(0,0,0,0.2)';
              menu.style.zIndex = '1000';

              const copyIdOption = document.createElement('div');
              copyIdOption.innerText = 'Copy Item ID';
              copyIdOption.style.cursor = 'pointer';
              copyIdOption.style.padding = '5px';
              copyIdOption.addEventListener('click', () => {
                archiveStore.copyItemIdToClipboard(id);
                mapContainer.removeChild(menu);
              });

              const copyOutcomeIdOption = document.createElement('div');
              copyOutcomeIdOption.innerText = 'Copy Outcome ID';
              copyOutcomeIdOption.style.cursor = 'pointer';
              copyOutcomeIdOption.style.padding = '5px';
              copyOutcomeIdOption.addEventListener('click', () => {
                if (outcomeId) {
                  archiveStore.copyItemIdToClipboard(outcomeId);
                } else {
                  console.warn('Outcome ID not found for this item');
                  // Optionally, show a notification to the user that the Outcome ID is not available
                }
                mapContainer.removeChild(menu);
              });

              menu.appendChild(copyIdOption);
              menu.appendChild(copyOutcomeIdOption);

              if (userStore.hasGaiaPermission()) {
                const sendToGaiaOption = document.createElement('div');
                sendToGaiaOption.innerText = 'Send to Gaia';
                sendToGaiaOption.style.cursor = 'pointer';
                sendToGaiaOption.style.padding = '5px';
                sendToGaiaOption.addEventListener('click', () => {
                  const gaiaIframe = document.querySelector('iframe[id^="GaiaFrame"]');
                  if (gaiaIframe) {
                    (gaiaIframe as HTMLIFrameElement).contentWindow?.postMessage({
                      type: 'Aleph_input:prompt',
                      text: 'Please analyze tile ' + id + ', make a focus on ALL'
                    }, '*');
                    console.log('Message sent to Gaia iframe');
                  } else {
                    console.warn('Gaia iframe not found');
                  }
                  mapContainer.removeChild(menu);
                });
                menu.appendChild(sendToGaiaOption);
              }

              mapContainer.appendChild(menu);

              // Remove menu on any click (including right-click)
              const removeMenu = (event: MouseEvent) => {
                if (menu && mapContainer.contains(menu) && !menu.contains(event.target as Node)) {
                  mapContainer.removeChild(menu);
                  document.removeEventListener('click', removeMenu);
                  document.removeEventListener('contextmenu', removeMenu);
                }
              };

              document.addEventListener('click', removeMenu);
              document.addEventListener('contextmenu', removeMenu);

              // Ensure cleanup after a short delay
              setTimeout(() => {
                if (menu && mapContainer.contains(menu)) {
                  mapContainer.removeChild(menu);
                }
                document.removeEventListener('click', removeMenu);
                document.removeEventListener('contextmenu', removeMenu);
              }, 5000); // 5 seconds delay
            }
          }
        }
      };

      map.on('click', (e: any) => handleMapClick(e));
      map.on('contextmenu', handleRightClick);

      return () => {
        //@ts-ignore
        map.off('click', handleMapClick);
        map.off('contextmenu', handleRightClick);

        if (mapRef && drawRef.current) {
          //const map = mapRef.getMap() as MaplibreMap;
          //archiveStore.removeDrawControl(map);
          //drawRef.current = null;
        }
      };
    }
  }, [mapRef, getTokenFromStore]);

  useEffect(() => {
    const disposer = autorun(() => {

      if (
        mapRef &&
        mapRef.getMap() &&
        archiveStore.aoiSearchMode !== "UploadGeoJSON" &&
        mapRef.getMap().getLayer("geojson")
      ) {
        mapRef.getMap().removeLayer("geojson");
        mapRef.getMap().removeSource("geojson");
        handleStopDrawPolygon();
      }

      if (
        mapRef &&
        mapRef.getMap() &&
        archiveStore.aoiSearchMode === "DrawPolygon"
      ) {
        handleDrawPolygon()
      }

      if (
        mapRef &&
        mapRef.getMap() &&
        archiveStore.aoiSearchMode === "CurrentCanvas" &&
        archiveStore.selectedTab === "Search"
      ) {

        handleStopDrawPolygon();

        const bounds = mapRef.getMap().getBounds();
        handleUpdatedBounds(bounds);
      }


      if (
        mapRef &&
        mapRef.getMap() &&
        (archiveStore.aoiSearchMode === "UploadGeoJSON") &&
        !!archiveStore.aoiPolygon
      ) {
        const map = mapRef?.getMap();

        const { error, description, geometry } = getGeometry(JSON.stringify(archiveStore.aoiPolygon))

        try {
          if (error || !geometry) throw new Error()
          if (map) {
            const polygonArea = getPolygonArea(geometry.coordinates)
            archiveStore.setAOIArea(Number(polygonArea))
          }

          handleStopDrawPolygon();
        } catch {
          //geoJSONError(errorMessage)
        }
      }

    });
    return () => disposer();
  }, [mapRef]);


  useEffect(() => {
    if (
      mapRef &&
      archiveStore.activeProductData) {
      mapRef.getMap().moveLayer("product-item-fill");
      mapRef.getMap().moveLayer("product-item-outline");
      mapRef.getMap().moveLayer("watermark-layer");
    }

  }, [mapRef, archiveStore.activeProductData]);


  useEffect(() => {
    handleTabChange(archiveStore.selectedTab)
  }, [archiveStore.selectedTab])

  const handleDeclutterPopUpHover = async (layer: FeatureSearch) => {
    handleSelectCapture(
      layer,
      await getTokenFromStore(),
      updateTabStatus,
      {
        'fitCapture': false,
        'openInfoTab': false,
      })
  };

  const handleDeclutterPopUpClick = async (layer: FeatureSearch) => {
    handleSelectCapture(layer, await getTokenFromStore(), updateTabStatus)
    setdeclutterPopupInfo(null);
  };

  const removeQueryParam = (param: any) => {
    const url = new URL(window.location.href); // Create a new URL object based on the current window location
    const params = new URLSearchParams(url.search); // Create a URLSearchParams object based on the current URL's search string

    if (params.has(param)) {
      params.delete(param); // Remove the specified query parameter
      const newUrl = `${url.pathname}?${params.toString()}`; // Construct the new URL without the removed parameter
      window.history.replaceState({}, '', newUrl); // Use replaceState to update the browser history without reloading
    }
  };

  const handleTabChange = (tab: string) => {
    if (tab == "Results") {
      handleUnselectCapture(updateTabStatus);

      if (
        archiveStore.searchResultsThumbnail &&
        archiveStore.maplibreMap
      ) {
        const map = archiveStore.maplibreMap
        const resulsbbox = calculateOverallBBox(archiveStore.searchResultsThumbnail.features);

        const aoibbox = geoJsonBBOX(toJS(archiveStore.aoiPolygon));

        let bounds: LngLatBounds;


        if (isContained(resulsbbox, aoibbox)) {
          bounds = new maplibregl.LngLatBounds(
            [resulsbbox[0], resulsbbox[1]], // southwest corner
            [resulsbbox[2], resulsbbox[3]]  // northeast corner
          );
        } else {
          bounds = new maplibregl.LngLatBounds(
            [aoibbox[0], aoibbox[1]], // southwest corner
            [aoibbox[2], aoibbox[3]]  // northeast corner
          );
        }


        map.fitBounds(bounds, {
          padding: 20,  // Optional: Adds padding around the bounds
          essential: true // This animation is considered essential with respect to prefers-reduced-motion
        });
      }
    }
    if (tab == "Results" || tab == "Search") {
      removeQueryParam('outcome_id');
      updateTabStatus("Details", false)
    }
  }


  ReactModal.setAppElement('#root');

  maplibregl.addProtocol('tiles', async (requestParameters: RequestParameters, abortController: AbortController) => {

    const url = requestParameters.url.replace('tiles://', 'https://'); // replace custom protocol with actual URL

    const token = await getTokenFromStore();

    const fetchOptions = {
      headers: {
        'authorizationToken': `Bearer ${token}`,
      },
      signal: abortController.signal // Attach the abort signal to the fetch options
    };
    const t = await fetch(url, fetchOptions);
    if (t.status == 200) {
      const buffer = await t.arrayBuffer();

      return { data: buffer }
    } else {
      throw new Error(`Tile fetch error: ${t.statusText}`);
    }
  });

  return (

    <Layout>
      <BottomContainer>

        <LeftPane>
          <TopBarTitle>Archive</TopBarTitle>
          <div style={{
            height: "97%",
            marginTop: '-1rem',
          }}>
            <TabsContainer
              tabs={tabs}
              selectedTab={archiveStore.selectedTab}
              setSelectedTab={archiveStore.setSelectedTab}
              onTabChange={handleTabChange}
            />
          </div>
        </LeftPane>
        <MapContainer>
          <ContractConstraint requireGaiaPermission={true}>
            <GaiaFrame></GaiaFrame>
          </ContractConstraint>
          <Map
            maxZoom={18}
            ref={ref => setMapRef(ref)}
            style={{ width: '100%', height: '100%' }}
            mapStyle="https://tiles.stadiamaps.com/styles/alidade_smooth.json"
            interactive
            initialViewState={{
              longitude: 0,
              latitude: 0,
              zoom: 1.75
            }}
            interactiveLayerIds={['tileCardLayer']}
          >

            <pre
              id="info"
              style={{
                display: 'table',
                position: 'absolute',
                left: '0',
                bottom: '0',
                margin: '0px auto',
                //wordWrap: 'anywhere',
                whiteSpace: 'pre-wrap',
                padding: '10px',
                border: 'none',
                borderRadius: '3',
                fontSize: '12',
                textAlign: 'left',
                color: '#222',
                background: '#fff0',
              }}
            >
              {moveEvent && (
                <>
                  Pointer coordinates: {moveEvent.map(item => item.toFixed(2)).reverse().join(' ')}. Zoom level: {mapRef?.getZoom().toFixed(1)}
                </>
              )}
            </pre>

            {!!archiveStore.aoiPolygon ? (
              <>

                <Source
                  id="searchAOI"
                  type="geojson"
                  data={archiveStore.aoiPolygon}
                >
                  <Layer
                    id={`searchAOI-layer`}
                    source="searchAOI"
                    type="line"
                    layout={{
                      'line-join': 'round',
                      'line-cap': 'round'
                    }}
                    paint={{
                      'line-color': '#3bb2d0',
                      'line-width': 2
                    }}
                  />
                </Source>


              </>
            ) : null}

            {archiveStore.showMap && !!archiveStore.searchResultsThumbnail && !archiveStore.activeProductData ? (
              // Actual image raster thumbnail
              <>
                {archiveStore.searchResultsThumbnail?.features.map(elem => {
                  return (
                    <Source
                      id={elem.id}
                      type="image"
                      url={elem.assets.preview.href}
                      //@ts-ignore
                      coordinates={getRasterCoordinatesFromGeoJSON(elem.bbox)}
                      key={`${elem.id}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}`}
                    >
                      <Layer
                        id={`thumbnail-${elem.id}`}
                        source={elem.id}
                        type="raster"
                        paint={{
                          'raster-fade-duration': 0,
                        }}
                        key={`${elem.id}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}`}
                      />
                    </Source>
                  );
                })}
              </>
            ) : null}

            {archiveStore.showMap && !!archiveStore.activeProductData ? (
              // Actual image items raster
              <>
                {archiveStore.activeProductData?.features.map(elem => {
                  if (!hiddenItems.has(elem.id)) {

                    if (elem.properties['satl:product_name'] != "L0"
                      && elem.properties['satl:product_name'] != "L1A"
                      && elem.properties['satl:product_name'] != "L1C"
                    ) {

                      let asset: Asset
                      let bands: string

                      switch (elem.properties['satl:product_name'].toLocaleLowerCase()) {
                        case "l1d":
                          asset = elem.assets.visual
                          bands = "bidx=1&bidx=2&bidx=3"
                          break;

                        case "l1d-sr":
                          asset = elem.assets.visual
                          bands = "bidx=1&bidx=2&bidx=3"
                          break;

                        case "quickview_visual":                        
                          asset = elem.assets.analytic
                          bands = "bidx=1&bidx=2&bidx=3"
                          break;

                        case "l1b":                          
                          asset = elem.assets.visual
                          bands = "bidx=1&bidx=2&bidx=3"
                          break;

                        default:
                          asset = elem.assets.analytic
                          bands = "bidx=1&bidx=2&bidx=3"
                          break;
                      }


                      const href = PRESIGNED_OR_S3 == "PRESIGNED" ? asset.href : convertPresignedUrlToS3Uri(asset.href);

                      return (
                        <Source
                          id={`${elem.properties['satl:product_name']}-${elem.id}`}
                          type="raster"
                          tiles={[`${TILER_URI}/cog/tiles/{z}/{x}/{y}?scale=2&tileMatrixSetId=WebMercatorQuad&url=${encodeURIComponent(href)}&${bands}&format=png`]}
                          //@ts-ignore
                          bounds={geoJsonBBOX(elem)}
                          minzoom={9}
                          maxzoom={19}
                          key={`${elem.id}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}`}
                        >
                          <Layer
                            id={`${elem.properties['satl:product_name']}-${elem.id}-raster`}
                            source={`${elem.properties['satl:product_name']}-${elem.id}`}
                            type="raster"
                            z-index="4"
                            paint={{
                              'raster-fade-duration': 0,
                            }}
                          />
                        </Source>
                      );
                    } else {
                      const coordinates = elem.geometry.coordinates[0].slice(1, 5);
                      const correctedCoordinates = bboxPolygon(elem.bbox as BBox)["geometry"]["coordinates"][0].slice(0, 4).reverse()
                      
                      return (                                                
                        <Source
                          id={`${elem.properties['satl:product_name']}-${elem.id}`}
                          type="image"
                          url={elem.assets.preview.href}
                          //@ts-ignore                          
                          coordinates={elem.properties['satl:product_name'] === "L1C"?correctedCoordinates:coordinates.reverse()}
                          key={`${elem.id}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}`}
                        >
                          <Layer
                            id={`${elem.properties['satl:product_name']}-${elem.id}-raster`}
                            source={`${elem.properties['satl:product_name']}-${elem.id}`}
                            type="raster"
                            paint={{
                              'raster-fade-duration': 0,
                            }}
                          />
                        </Source>
                      );
                    }

                  } else {
                    return ""
                  }
                })}
              </>
            ) : null}

            {archiveStore.showMap && !!archiveStore.searchResultsThumbnail ? (
              // Outline of the capture
              <>
                {
                  archiveStore.searchResultsThumbnail?.features.map(elem => {
                    return (
                      <Source
                        id={`source-outline-${elem.id}`}
                        type="geojson"
                        data={convertToGeoJSON(elem.geometry)}
                        key={`${elem.id}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}`}
                      >
                        <Layer
                          id={`layer-outline-${elem.id}`}
                          source={`source-outline-${elem.id}`}
                          type="fill"
                          paint={{
                            'fill-color': '#54B3DB',
                            'fill-opacity': 0.00
                          }}
                          key={`${elem.id}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}`}
                        />
                      </Source>
                    );
                  })}
              </>
            ) : null}


            {archiveStore.showMap && !!archiveStore.activeProductData ? (
              // item(tile/frame) information, for selection
              <Source
                type="geojson"
                id="product-item"
                data={archiveStore.activeProductData}
              >
                <Layer
                  id={`product-item-fill`}
                  type="fill"
                  source="product-item"
                  paint={{
                    'fill-color': '#54B3DB',
                    'fill-opacity': 0.0
                  }}
                />
                <Layer
                  id={`product-item-outline`}
                  type="line"
                  source="product-item"
                  paint={{
                    'line-color': [
                      'case',
                      ['in', ['get', 'id'], ['literal', Array.from(selectedItems)]], '#FF0000',
                      ['==', ['get', 'id'], ['literal', !!archiveStore.activeMetadataItem ? archiveStore.activeMetadataItem : ""]], '#00FF00',
                      '#000000'  // Default color
                    ],
                    'line-width': [
                      'case',
                      ['in', ['get', 'id'], ['literal', Array.from(selectedItems)]], 1.5,
                      ['==', ['get', 'id'], ['literal', !!archiveStore.activeMetadataItem ? archiveStore.activeMetadataItem : ""]], 3.0,
                      0.15  // Default width
                    ]
                  }}
                />
              </Source>

            ) : null}

            {declutterPopupInfo && (
              <Popup
                longitude={declutterPopupInfo.lngLat.lng}
                latitude={declutterPopupInfo.lngLat.lat}
                offset={100}
                closeButton={true}
                onClose={() => setdeclutterPopupInfo(null)}
                closeOnClick={false}
                maxWidth="650px"
              >
                <PopUpContainer>
                  There are multiple captures in the area clicked. Select one:
                  <ul>
                    {
                      declutterPopupInfo.items
                        .sort((a: FeatureSearch, b: FeatureSearch) => {
                          console.log(new Date(a.properties.datetime))
                          return new Date(b.properties.datetime).getTime() - new Date(a.properties.datetime).getTime();
                        })
                        .map((item: FeatureSearch) => (
                          <li
                            key={item.id}
                            onMouseEnter={() => handleDeclutterPopUpHover(item)}
                            onMouseLeave={() => handleUnselectCapture(updateTabStatus)}
                            onClick={() => handleDeclutterPopUpClick(item)}
                            style={{
                              cursor: 'pointer',
                              listStyleType: 'none',
                            }}

                          >
                            <LinkButton >
                              {transformDateTime(item.properties.datetime)}, {item.properties.platform}, {item.properties['eo:cloud_cover'].toFixed(0)}% clouds, {item.properties['view:off_nadir'].toFixed(0)}° ONA
                            </LinkButton>
                          </li>
                        ))
                    }
                  </ul>
                </PopUpContainer>
              </Popup>
            )}

            {!!archiveStore.limitsSearchPolygon ? (
              <Source
                id="limitsSearchPolygon"
                type="geojson"
                data={archiveStore.limitsSearchPolygon}
              >
                <Layer
                  id="limitsSearchPolygon-dashed"
                  type="line"
                  paint={{
                    'line-color': '#002340',
                    'line-width': 2
                  }}
                />
              </Source>
            ) : null}

            {!!archiveStore.aoiPolygon ? (
              <Source
                id="watermark-source"
                type="geojson"
                data={archiveStore.aoiPolygon}
              >
                <Layer
                  id="watermark-layer"
                  type="fill"
                  paint={{
                    'fill-opacity': 0.2,
                    'fill-pattern': 'watermark'
                  }}
                />
              </Source>
            ) : null}
          </Map>


          <SlidingPane
            //className="some-custom-class"
            //overlayClassName="some-custom-overlay-class"
            isOpen={archiveStore.activeMetadataItem != null ? true : false}
            title="Hey, it is optional pane title.  I can be React component too."
            subtitle="Optional subtitle."
            width='20%'
            onRequestClose={() => { setTelemetryPaneState(false); archiveStore.setActiveMetadataItem(null) }}
          >
            <MetadataFrame id={archiveStore.activeMetadataItem}></MetadataFrame>
          </SlidingPane>

        </MapContainer>
      </BottomContainer>
    </Layout>
  );
});

Archive.displayName = 'Archive';
