import styled from '@emotion/styled/macro';

interface InputProps {
  readonly ratio: number;
}

interface TooltipProps {
  readonly progress: number;
  readonly currentValue: number;
  readonly min: number;
  readonly max: number;
  readonly trackWidth?: number;
}

export const Label = styled.label`
  color: ${(props: any) => props.theme.neutral80};
  display: block;  
  line-height: 20px;
  margin-bottom: ${(props: any) => props.theme.sizes.xxsmall};
  ${(props: any) => props.theme.small.styles};
`;

export const Tooltip = styled.output<TooltipProps>`
  background: ${(props: any) => props.theme.primary};
  color: ${(props: any) => props.theme.neutral};
  display: none;
  font-weight: 500;
  padding: 10px 16px;
  pointer-events: none;
  text-align: center;
  transform: translate(
    calc(
      ${(props: any) =>
        `(
          ${props.currentValue} - ${props.min}
        ) / (
          ${props.max} - ${props.min}
        ) * calc(${props.trackWidth}px - 20px) - 50%`}
    )
  );
  position: absolute;
  bottom: 65px;
  left: 10px;

  :after {
    width: 0;
    height: 0;
    border-color: ${(props: any) => props.theme.primary} transparent transparent transparent;
    border-style: solid;
    border-width: 10px;
    content: '';
    transform: translateX(-50%);
    position: absolute;
    top: 35px;
    left: 50%;
  }
`;

export const Input = styled.input<InputProps>`
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  height: 20px;
  width: 100%;
  opacity: 0;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px ${(props: any) => props.theme.primary};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    pointer-events: all;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px ${(props: any) => props.theme.primary};
    cursor: pointer;
  }

  &::-ms-thumb {
    pointer-events: all;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px ${(props: any) => props.theme.primary};
    cursor: pointer;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0px 0;

  .range-slider {
    position: relative;
    width: 100%;
    height: 10px;
  }

  .slider-track {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 4px;
    background-color: ${(props: any) => props.theme.neutral10};
  }

  .slider-range {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    background-color: ${(props: any) => props.theme.primary};
  }

  .minMaxValues {
    font-size: small;    
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    .min {
      transform: translateX(calc(-50% + 5px));
    }
    .max {
      transform: translateX(calc(50% - 5px));
    }
  }

  .markers {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    transform: translateY(-14px);
    .mark {
      width: 2px;
      height: 2px;
      background: ${(props: any) => props.theme.neutral40};
      border-radius: 50%;
      margin: 0 9px;
      pointer-events: none;
      &.hidden {
        opacity: 0;
      }
    }
  }

  &.disabled {
    #currentValues {
      display: none;      
    }

    ${Input} {
      &:active ~ ${Tooltip} {
        display: none;
      }

      &::-webkit-slider-thumb {
        border: 1px solid ${(props: any) => props.theme.neutral40};
        cursor: not-allowed;
      }

      &::-moz-range-thumb {
        border: 1px solid ${(props: any) => props.theme.neutral40};
        cursor: not-allowed;
      }

      &::-ms-thumb {
        border: 1px solid ${(props: any) => props.theme.neutral40};
        cursor: not-allowed;
      }
    }

    .slider-track, .slider-range {
      background-color: ${(props: any) => props.theme.neutral40};
    }
  }
`;