import React, { useRef } from 'react';
import { FileUpload } from 'components/fileupload'; // Adjust the import path as necessary

import './UploadButtonWrapper.css'; // Adjust the import path as necessary
import AOIModeButtonWrapper from './AOIModeButtonWrapper'

interface UploadButtonWrapperProps {
  onFileUpload?: (file: File) => void;
  icon?: JSX.Element;
  text?: string;
  disabled?: boolean;
}

const UploadButtonWrapper: React.FC<UploadButtonWrapperProps> = ({ onFileUpload, icon, text, disabled }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (files: FileList | null) => {    
    if (files && files.length > 0) {
      const file = files[0]; // Assuming single file upload
      if (onFileUpload) {
        onFileUpload(file);
      }
    }    
  };

  return (
    <div>
      <AOIModeButtonWrapper
        text={text}
        medium        
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        secondary
        icon={icon}
        disabled={disabled}
      />
      <input
        type="file"
        accept=".json,.geojson,.kml,.kmz"        
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleFileUpload(e.target.files)}
        onClick={(event) => { event.currentTarget.value = "" }}
      />
      <div className="hidden-label">
        <FileUpload
          name="geojson-file-upload"
          onChange={(files) => handleFileUpload(files)}
          fileFormats=".json,.geojson,.kml,.kmz"
          label="Click or drag a file here"
          clearInput
          helperText="Only .json, .geojson, .kml and .kmz files are allowed"
        />
      </div>
    </div>
  );
};

export default UploadButtonWrapper;