import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import 'themes/global.css'
import App from './geodas'
import { ThemeProvider } from '@emotion/react'
import { defaultTheme } from 'themes/default'
import 'react-tooltip/dist/react-tooltip.css'



const root = createRoot(document.getElementById('root')!)
const app: any = (
  <ThemeProvider theme={defaultTheme}>
    <App />
  </ThemeProvider>
)
root.render(app)


