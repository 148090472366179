import React, { useState, useRef, useCallback, useEffect } from 'react';
import { InputWrapper, Label, Input, Tooltip } from './inputrange.styles';
import { classes } from 'config/common';

interface Props {
  className?: string;
  id: string;
  min: number;
  max: number;
  step?: number;
  defaultMinValue?: number;
  defaultMaxValue?: number;
  onChange?: (minValue: number, maxValue: number) => void;
  label?: string;
  isPercentage?: boolean;
  disabled?: boolean;
  tooltipLabels?: string[];
  suffix?: string;
}

export const InputRange = ({
  className,
  min,
  max,
  step = 1,
  defaultMinValue,
  defaultMaxValue,
  onChange,
  label,
  isPercentage = false,
  disabled = false,
  tooltipLabels,  
  suffix="",
  id
}: Props) => {
  const [minValue, setMinValue] = useState(defaultMinValue || min);
  const [maxValue, setMaxValue] = useState(defaultMaxValue || max);

  const minRef = useRef<HTMLInputElement>(null);
  const maxRef = useRef<HTMLInputElement>(null);

  const updateSliderRange = useCallback(() => {
    if (minRef.current && maxRef.current) {
      const minPercent = ((minValue - min) / (max - min)) * 100;
      const maxPercent = ((maxValue - min) / (max - min)) * 100;
      
      const sliderRange = maxRef.current.parentElement?.querySelector('.slider-range') as HTMLElement;
      if (sliderRange) {
        sliderRange.style.left = `${minPercent}%`;
        sliderRange.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minValue, maxValue, min, max]);

  const handleMinChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMinValue = Math.min(Number(e.target.value), maxValue - step);
      setMinValue(newMinValue);
      if (onChange) {
        onChange(newMinValue, maxValue);
      }
      updateSliderRange();
    },
    [onChange, maxValue, step, updateSliderRange]
  );

  const handleMaxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMaxValue = Math.max(Number(e.target.value), minValue + step);
      setMaxValue(newMaxValue);
      if (onChange) {
        onChange(minValue, newMaxValue);
      }
      updateSliderRange();
    },
    [onChange, minValue, step, updateSliderRange]
  );

  const getTooltipLabel = (value: number) => {
    return tooltipLabels
      ? tooltipLabels[value]
      : isPercentage
      ? `${value}%`
      : value;
  };

  useEffect(() => {
    updateSliderRange();
  }, [minValue, maxValue, updateSliderRange]);

  let markers: number[] = [];
  for (let i = min; i <= max; i += step) {
    markers.push(i);
  }

  return (
    <InputWrapper className={classes(className, { disabled })}>
      
      {label && <Label style={{display: "flex", width:"100%"}} htmlFor={id}>{label}: <div id="currentValues" style={{marginLeft: "10px"}}>[{minValue}{isPercentage ?'%':suffix}, {maxValue}{isPercentage ?'%':suffix}]</div></Label>}
            
      <div className="range-slider">
        <div className="slider-track"></div>
        <div className="slider-range"></div>
        <Input
          ref={minRef}
          type="range"
          id={`${id}-min`}
          name={`${label}-min`}
          min={min}
          max={max}
          step={step}
          value={minValue}
          onChange={handleMinChange}
          ratio={(minValue - min) / (max - min)}
          disabled={disabled}
        />
        <Input
          ref={maxRef}
          type="range"
          id={`${id}-max`}
          name={`${label}-max`}
          min={min}
          max={max}
          step={step}
          value={maxValue}
          onChange={handleMaxChange}
          ratio={(maxValue - min) / (max - min)}
          disabled={disabled}
        />
      </div>
      <div className="markers">
        {markers.map(mark => (
          <div
            className={classes('mark', { hidden: mark === minValue || mark === maxValue })}
            key={mark}
          />
        ))}
      </div>
      <Tooltip
        htmlFor={`${id}-min`}
        progress={(minValue * 100) / max}
        currentValue={minValue}
        min={min}
        max={max}
        trackWidth={minRef.current?.getBoundingClientRect().width}
      >
        {getTooltipLabel(minValue)}
      </Tooltip>
      <Tooltip
        htmlFor={`${id}-max`}
        progress={(maxValue * 100) / max}
        currentValue={maxValue}
        min={min}
        max={max}
        trackWidth={maxRef.current?.getBoundingClientRect().width}
      >
        {getTooltipLabel(maxValue)}
      </Tooltip>
      <div className="minMaxValues">
        <span className="min">
          {tooltipLabels ? tooltipLabels[min] : isPercentage ? min + '%' : min + suffix}
        </span>
        <span className="max">
          {tooltipLabels ? tooltipLabels[max] : isPercentage ? max + '%' : max + suffix}
        </span>
      </div>
    </InputWrapper>
  );
};

InputRange.displayName = 'Input Range';