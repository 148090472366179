import { InputDateRange } from 'components/inputdaterange';
import archiveStore from 'stores/archiveStore';
import { Input } from 'components/input';
import { InputRange } from 'components/inputrange';
import { observer } from 'mobx-react-lite';
import UploadButtonWrapper from './UploadButtonWrapper'

import * as togeojson from '@tmcw/togeojson';
import { useStores } from 'config/hooks';
import { searchCollection } from 'services/archiveService';
import { toJS } from 'mobx';
import AOIModeButtonWrapper from './AOIModeButtonWrapper'

import {

  ButtonWrapper,
  ButtonsModeWrapper,
  FiltersWrapper,
  ModeButton,
  //  Title
} from 'views/archive/archive.styles';

import { Button } from 'components/button';

import { useCallback, useEffect, useState } from 'react';

import { FiltersIcon, CalendarIcon } from 'components/icons';
import { CollapsibleContent, TitleWithIcon } from './searchTab.styles';
import { MapIcon } from 'components/icons/icons';
import loadingNotificationStore from 'stores/loadingNotificationStore';
import { formatToEndOfDayUTC, formatToStartOfDayUTC, groupByOutcomeId, transformDateTime } from 'views/archive/helpers';
import { useTabContext } from 'views/archive/TabContext';
import ReactGA from "react-ga4";
import { addDays, endOfDay, isAfter, startOfDay } from 'date-fns';
import { findMinMaxDates, isFeature, isFeatureCollection } from './helpers';
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js';
import { BaseModal } from 'components/modal/basemodal';
import { ConfirmationModal } from 'components/modal/confirmationmodal';
import Icon from '@mdi/react';


let setButtonDisabled_toBeExported: (disabled: boolean) => void;

export const setDisableButtonSearch = (disabled: boolean) => {
  setButtonDisabled_toBeExported(disabled);
};

export const SearchTab = observer(() => {
  const [disableButtonSearch, setDisableButtonSearch] = useState<boolean>(true);
  const [searchingFlag, setSearchingFlag] = useState<boolean>(true);
  const [searchByOutcomeID, setSearchByOutcomeID] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  const [temporalSearchData, setTemporalSearchData] = useState<StacSearchResponse | null>(null);
  const [temporalMinDate, setTemporalMinDate] = useState<Date | null>(null);
  const [temporalMaxDate, setTemporalMaxDate] = useState<Date | null>(null);
  const { tabs, updateTabStatus } = useTabContext();
  const [isDetailedSearchCollapsed, setIsDetailedSearchCollapsed] = useState(true);

  const toggleDetailedSearch = () => {
    setIsDetailedSearchCollapsed(!isDetailedSearchCollapsed);
  };

  setButtonDisabled_toBeExported = setDisableButtonSearch;

  const handleFileUpload = (file: File) => {

    const reader = new FileReader();
    reader.onload = (event) => {


      if (event.target && event.target.result) {
        try {
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          let geoJson = undefined;

          switch (fileExtension) {
            case "geojson":
            case "json":
              geoJson = JSON.parse(event.target.result as string);

              if (isFeature(geoJson)) {
                // Its already one                           
              }
              else if (isFeatureCollection(geoJson)) {
                if (geoJson.features.length === 1) {
                  geoJson = geoJson.features[0];
                } else {
                  throw new Error('GeoJSON FeatureCollection must contain exactly one feature.');
                }
              }
              else {
                throw new Error('GeoJSON must be a Feature or a FeatureCollection with exactly one feature.');
              }
              break;

            case "kml":
              const parser = new DOMParser();
              const kmlDoc = parser.parseFromString(event.target.result as string, 'text/xml');

              geoJson = togeojson.kml(kmlDoc);
              break;

            default:
              throw new Error('Not supported file type');
          }

          archiveStore.setAoiPolygon(geoJson);
          archiveStore.setAoiSearchMode("UploadGeoJSON");

        } catch (error) {
          console.error('Invalid GeoJSON file:', error);
        }
      }
    };
    reader.readAsText(file);
  };

  const {
    rootStore: { userStore }
  } = useStores();

  const getTokenFromStore = useCallback(async () => {
    const token = await userStore.auth0Client?.getTokenSilently();
    return token;
  }, [userStore]);

  useEffect(() => {

    if (!!archiveStore.dateStart && !!archiveStore.dateEnd && !!archiveStore.aoiPolygon) {

      if (archiveStore.aoiArea <= 10000000) {
        if (!!searchingFlag) {
          setDisableButtonSearch(false);
        }
      } else {
        setDisableButtonSearch(true);
      }
    } else {
      setDisableButtonSearch(true);
    }
  }, [
    archiveStore.dateStart,
    archiveStore.dateEnd,
    archiveStore.aoiPolygon,
  ]);


  const handleSearchClick = async () => {
    if (!!archiveStore.activeCapture) {
      archiveStore.setActiveCapture(null);
      archiveStore.clearActiveCaptureData();
      archiveStore.setActiveProductData(null);
    }

    setSearchingFlag(true);
    setDisableButtonSearch(true);

    loadingNotificationStore.setText('Searching...');
    loadingNotificationStore.setLoading(true);
    loadingNotificationStore.setNoResults(false);

    try {

      let searchCollectionParams;

      if (searchByOutcomeID) {
        
        // Step 1: Filter the 'args' array to include only the 'satl:outcome_id' filters
        const updatedArgs = toJS(archiveStore.filtersQueriables.filter.args).filter(
          (filter: any) =>
            filter.args.some((arg: any) => arg.property === "satl:outcome_id")
        );

        // Step 2: Create a new object with the updated 'args' array while keeping other properties intact
        const updatedFilter = {
          ...archiveStore.filtersQueriables.filter, // Spread operator to copy all properties
          args: updatedArgs // Override 'args' with the filtered array
        };
        
        searchCollectionParams = {
          collections: ['quickview-visual-thumb'],
          limitSearchAmount: '10',
          filter:
            updatedFilter.args.length > 0
              ? updatedFilter
              : undefined
        }

      } else {
        searchCollectionParams = {
          startDate: formatToStartOfDayUTC(archiveStore.dateStart),
          endDate: formatToEndOfDayUTC(archiveStore.dateEnd),
          collections: ['quickview-visual-thumb'],
          limitSearchAmount: '10',
          polygon: toJS(archiveStore.aoiPolygon.geometry.coordinates),
          filter:
            archiveStore.filtersQueriables.filter.args.length > 0
              ? archiveStore.filtersQueriables
              : undefined,
        }
      }

      const token = await getTokenFromStore();

      const searchData = await searchCollection(token, searchCollectionParams);
      // Send a custom event
      ReactGA.event({
        category: "search",
        action: JSON.stringify(searchCollectionParams),
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

      if (searchData && searchData.features.length > 0) {
        archiveStore.setSearchResultsThumbnail(searchData);
        archiveStore.setShowMap(true);
        loadingNotificationStore.setLoading(false);

        updateTabStatus("Results", true);
        archiveStore.setSelectedTab("Results");

      } else {
        loadingNotificationStore.setLoading(false);
        loadingNotificationStore.setNoResults(true);

        // Helper functions to adjust dates
        const expandByDays = (startDate: Date, endDate: Date, days: number) => {
          const newStartDate = addDays(startDate, -days);
          const newEndDate = addDays(endDate, days);
          return { newStartDate: startOfDay(newStartDate), newEndDate: endOfDay(newEndDate) };
        };


        const currentStartDate = new Date(archiveStore.dateStart);
        const currentEndDate = new Date(archiveStore.dateEnd);

        const expandedRange = expandByDays(currentStartDate, currentEndDate, 30 * 6);

        if (isAfter(expandedRange.newEndDate, new Date())) {
          expandedRange.newEndDate = endOfDay(new Date());
        }

        const newSearchParams = {
          ...searchCollectionParams,
          startDate: formatToStartOfDayUTC(expandedRange.newStartDate),
          endDate: formatToEndOfDayUTC(expandedRange.newEndDate),
        };

        loadingNotificationStore.setText('Searching...');
        loadingNotificationStore.setLoading(true);

        setModalMessage("searching")
        loadingNotificationStore.setModal(true);

        const searchData = await searchCollection(token, newSearchParams);

        if (searchData.context.matched > 0) {
          setTemporalSearchData(searchData);

          const { minDate, maxDate } = findMinMaxDates(searchData);
          if (!!minDate && !!maxDate) {
            setTemporalMinDate(minDate);
            setTemporalMaxDate(maxDate);
          }
          console.log(temporalMinDate)
          setModalMessage("thereismoredata")
        } else {
          setTemporalMinDate(expandedRange.newStartDate);
          setTemporalMaxDate(expandedRange.newEndDate);
          setModalMessage("nodata")
        }

      }
    } catch (error) {
      console.error("Error processing search collection:", error);
      loadingNotificationStore.setLoading(false);
    }

    setDisableButtonSearch(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "satl:outcome_id" && value !== "") {
      setSearchByOutcomeID(true);
    } else {
      setSearchByOutcomeID(false);
    }



    if (value.includes(",")) {
      const parsedValue = value.split(",").map((item) => item.trim());

      archiveStore.setDropdownFilterValues(name, parsedValue);
      archiveStore.updateArgs(name, parsedValue, "in");
    } else {
      const parsedValue =
        e.target.type === 'number' ? (value === '' ? undefined : parseFloat(value)) : value;

      archiveStore.setDropdownFilterValues(name, parsedValue);
      archiveStore.updateArgs(name, parsedValue, "=");
    }

  };

  const [cloudCoverRange, setCloudCoverRange] = useState<{ minValue: number, maxValue: number }>({ minValue: 0, maxValue: 25 });
  const [ONARange, setONARange] = useState<{ minValue: number, maxValue: number }>({ minValue: 0, maxValue: 30 });

  const handleCloudCoverInputChange = (min: number, max: number) => {
    setCloudCoverRange({ minValue: min, maxValue: max });
    archiveStore.updateArgs("eo:cloud_cover", [cloudCoverRange.minValue, cloudCoverRange.maxValue], "between");
  };

  const handleONAInputChange = (min: number, max: number) => {
    setONARange({ minValue: min, maxValue: max });
    archiveStore.updateArgs("view:off_nadir", [ONARange.minValue, ONARange.maxValue], "between");
  };

  const handleExpandSearch = () => {
    if (temporalMaxDate && temporalMinDate) {
      archiveStore.setDateEnd(temporalMaxDate);
      archiveStore.setDateStart(temporalMinDate);
      handleSearchClick()
    }
  };

  const handleCollectDataHere = () => {
    ReactGA.event({
      category: "needdata",
      action: JSON.stringify(archiveStore.aoiPolygon),
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    loadingNotificationStore.setModal(false);

  }

  useEffect(() => {
    // Set the defaults as args, if not, we need the user to change a value    
    archiveStore.updateArgs("eo:cloud_cover", [cloudCoverRange.minValue, cloudCoverRange.maxValue], "between");
    archiveStore.updateArgs("view:off_nadir", [ONARange.minValue, ONARange.maxValue], "between");
  }, []);

  const customStyles = {
    content: {
      width: '40%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  return (
    <>
      <BaseModal
        isShown={loadingNotificationStore.modalIsOpen && modalMessage === "searching"}
        hide={function (): void {
          throw new Error('Function not implemented.');
        }}>
        <div>
          No data was found for the given filters.
          <br />
          <br />
          Expanding search in time to see if there is more data...
        </div>
      </BaseModal>


      <ConfirmationModal
        isShown={loadingNotificationStore.modalIsOpen && modalMessage === "thereismoredata"}
        title={'We found more data'}
        text={`
            There ${temporalSearchData?.context.matched && temporalSearchData?.context.matched > 1 ? "are" : "is"} 
            ${temporalSearchData?.context.matched} capture${temporalSearchData?.context.matched && temporalSearchData?.context.matched > 1 ? "s" : ""} 
            in the ${transformDateTime(temporalMinDate ? temporalMinDate.toISOString() : "")} - ${transformDateTime(temporalMaxDate ? temporalMaxDate.toISOString() : "")} range.
            Do you want to expand the search to that period of time?
            `}
        acceptText={'Expand time range and search'}
        cancelText={'No thanks.'}

        onCancel={() => loadingNotificationStore.setModal(false)}
        onClose={() => loadingNotificationStore.setModal(false)}
        onConfirm={handleExpandSearch}
      />

      <ConfirmationModal
        isShown={loadingNotificationStore.modalIsOpen && modalMessage === "nodata"}
        title={'No data found'}
        text={`
            Ups! It seems that there is no data in the archive for the provided filters. <br/><br/>
            We also tried to find data within the ${transformDateTime(temporalMinDate ? temporalMinDate.toISOString() : "")} - ${transformDateTime(temporalMaxDate ? temporalMaxDate.toISOString() : "")} preiod 
            but none was found.
            <br/><br/>
            Would you like us to collect data here?
            `}
        acceptText={'Please capture data here!'}
        cancelText={'I will refine my search'}
        onCancel={() => loadingNotificationStore.setModal(false)}
        onClose={() => loadingNotificationStore.setModal(false)}
        onConfirm={handleCollectDataHere}
      />



      <FiltersWrapper>
        <div className="inner">
          <TitleWithIcon>
            <MapIcon className="action--icon" />
            <h1>Area of interest</h1>
          </TitleWithIcon>

          <ButtonsModeWrapper>
            <ModeButton active={archiveStore.aoiSearchMode === "CurrentCanvas"}>

              <AOIModeButtonWrapper
                text="Current Canvas"
                medium
                onClick={() => {
                  archiveStore.setAoiSearchMode("CurrentCanvas");
                }}
                secondary
                disabled={searchByOutcomeID}
              />
            </ModeButton>

            <ModeButton active={archiveStore.aoiSearchMode === "DrawPolygon"}>
              <AOIModeButtonWrapper
                text="Draw Polygon"
                medium
                onClick={() => {
                  archiveStore.setAoiSearchMode("DrawPolygon");
                }}
                secondary
                disabled={searchByOutcomeID}
              />
            </ModeButton>

            <ModeButton active={archiveStore.aoiSearchMode === "UploadGeoJSON"}>
              <UploadButtonWrapper
                disabled={searchByOutcomeID}
                text="Upload AOI"
                onFileUpload={handleFileUpload} />
            </ModeButton>

          </ButtonsModeWrapper>
          <div>
            <br />
          </div>
          {archiveStore.aoiArea ? (
            <TitleWithIcon>
              Search Area: {archiveStore.aoiArea.toLocaleString("en-US", {
                maximumFractionDigits: 0,
                notation: 'compact',
                compactDisplay: 'short'
              })} km<sup>2</sup>
            </TitleWithIcon>
          ) :
            undefined
          }
        </div>
        <div className="inner">
          <TitleWithIcon>
            <CalendarIcon className="action--icon" />
            <h1>Date range</h1>
          </TitleWithIcon>

          <InputDateRange
            disabled={searchByOutcomeID}
            name="filter-date"
            startLabel="Since"
            endLabel="Until"
            maxStartDate={new Date()}
            maxEndDate={new Date()}
            monthsShown={1}
            startPlacement="bottom-start"
            endPlacement="bottom-end"
            onChangeStart={date => {
              const startDate = new Date(date);
              startDate.setHours(0, 0, 0, 0); // Set time to midnight
              archiveStore.setDateStart(startDate);
            }}
            onChangeEnd={date => {
              const endDate = new Date(date);
              endDate.setHours(23, 59, 59, 999); // Set time to end of the day
              archiveStore.setDateEnd(endDate);
            }}
            startValue={archiveStore.dateStart}
            endValue={archiveStore.dateEnd}
          />
        </div>
        <div className="inner">
          <TitleWithIcon>
            <FiltersIcon className="action--icon" />
            <p>Capture constraints</p>
          </TitleWithIcon>
          <InputRange
            disabled={searchByOutcomeID}
            id="cloud-cover-ramge"
            min={0}
            max={100}
            step={1}
            defaultMinValue={cloudCoverRange.minValue}
            defaultMaxValue={cloudCoverRange.maxValue}
            onChange={handleCloudCoverInputChange}
            label="Cloud cover"
            isPercentage={true}
          />
          <InputRange
            disabled={searchByOutcomeID}
            id="ona-range"
            min={0}
            max={60}
            step={1}
            suffix="°"
            defaultMinValue={ONARange.minValue}
            defaultMaxValue={ONARange.maxValue}
            onChange={handleONAInputChange}
            label="Off Nadir angle"
            isPercentage={false}
          />
        </div>
        <div className="inner">

          <TitleWithIcon onClick={toggleDetailedSearch}>
            <FiltersIcon className="action--icon" />

            <p>Detailed search {isDetailedSearchCollapsed ? <Icon path={mdiChevronDoubleDown} size={0.7} /> : <Icon path={mdiChevronDoubleUp} size={0.7} />}</p>
          </TitleWithIcon>
          {!isDetailedSearchCollapsed && (
            <CollapsibleContent
              style={{ maxHeight: isDetailedSearchCollapsed ? '0' : '300px' }} // Adjust the max-height as needed
            >
              <div>
              <Input              
                name="satl:outcome_id"
                label="Outcome ID"
                onChange={handleInputChange}
                helperText='Search by capture ID. Supports multiple ids separated by comma, disables all other filters'
                type="text"
                value={archiveStore.dropdownFilterValues['satl:outcome_id'] as string}                
              />
              </div>
              <Input
                disabled={searchByOutcomeID}
                name="platform"
                label="Satellite"
                onChange={handleInputChange}
                helperText='Search by satellite, for example newsat30. Supports multiple satellites separated by comma.'
                type="text"
                value={archiveStore.dropdownFilterValues['platform'] as string}
              />
            </CollapsibleContent>
          )}
        </div>
        <div id="do_search" className="inner">
          <ButtonWrapper>
            <Button
              text={!searchByOutcomeID ? disableButtonSearch ? "Search area is too big." : "Search" : "Search"}
              onClick={handleSearchClick}
              disabled={!searchByOutcomeID && disableButtonSearch}
            />
          </ButtonWrapper>

        </div>
      </FiltersWrapper>
    </>
  );
});

SearchTab.displayName = 'Search';
