import React, { useState, useEffect, useRef } from 'react';
import Icon from '@mdi/react';
import { mdiAttachment } from '@mdi/js';
import { Tooltip } from 'react-tooltip';
import { PopupContainer, PopupButton, PopupContent, AttachmentList, AttachmentItem, AttachmentLink, CustomTooltip } from './AttachmentPopup.styles';


interface AttachmentPopupProps {
  attachments: { [key: string]: Asset };
  id: string; // Unique ID passed from the parent
}

const AttachmentPopup: React.FC<AttachmentPopupProps> = ({ attachments, id }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const tooltip = (
    <CustomTooltip       
      render={ () =>(
        <AttachmentList>
        {Object.keys(attachments).map((key: string) => (
          <AttachmentItem key={key}>
            <AttachmentLink href={attachments[key].href} download={attachments[key].href}>
              {key} ({attachments[key].roles.join(",")})
            </AttachmentLink>
          </AttachmentItem>
        ))}
      </AttachmentList>
      )
      }      
      id={`attachmentTooltip-${id}-2`}      
      clickable={true}           
      border="1px solid darkblue"
      style={{ 
        backgroundColor: "rgb(255, 255, 255)", 
        color: "#FFF",               
      }}      
    >      
    </CustomTooltip>);

  return (
    <>      
      <PopupButton
        data-tooltip-id={`attachmentTooltip-${id}-2`}
        data-tooltip-content="Click to view attachments"
        aria-label="View attachments"
        data-tooltip-place="top"        
      >
        <Icon path={mdiAttachment} size={0.7} rotate={90} color={"darkblue"} />
      </PopupButton>
      
      {tooltip} 
    </>
  );
};

export default AttachmentPopup;